:root {
  --background-color: #969696;
  --header-height: 100px;
  --footer-height: 70px;
  --er-color: #5e5e5e;
  --pre-header-color: #7a7a7a;
  --pre-header-height: 0px;
}

* {
  color: white;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html, body {
  height: 100%;
  background-color:var(--background-color);
}

.app-footer {
  padding: 0 0 0 2rem;
  position: absolute;
  bottom: 0;
  height: var(--footer-height);
  width: 100%;
  display: flex;
  background-color: var(--er-color);
  align-items: center;
  color: white;
}

.app-footer h1{
  font-size: 20px;
}

.app-header {
  z-index: 1;
  padding: 2rem;
  height: var(--header-height);
  width: 100%;
  background-color: var(--er-color);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 5px 3px rgba(0,0,0,0.2);
}

.app-header h1 {
  width:100%;
  padding-left: 2rem;
  text-align: left;
  font-style: none;
}

.gameboard-container {
  margin-top: -35px;
  height: auto;
  width: auto;
}

.gameboard-container h1 {
  padding: 0.75rem;
}

.history-container {
  overflow: visible;
  overflow-x: hidden;
}

.history-container li {
  list-style: none;
  padding: 0.1rem;
}

.info-container {
  border: 1rem solid #404040;
  border-radius: 20px;
  background-color: #ababab;
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  margin-top: 2vh;
  width: 300px;
  height: 60vh;
  overflow-x: hidden;
}

.info-container button{
  background-color: #727272;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem 1rem;
}

.info-container h1{
  font-size: 25px;
  margin: 15px 0 10px 0;
}

.main-container {
  position: absolute;
  top: calc(var(--header-height) + var(--pre-header-height));
  bottom: var(--footer-height);
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.player-label{
  font-size: 2rem;
}

.pre-header {
  z-index: 9;
  background-color: var(--pre-header-color);
  height: var(--pre-header-height);
}

#root {
  height: 100%;
}

@media only screen and (max-width: 1080px) {
  .app-footer {
    position: relative;
  }
  .app-header {
    position: relative;
  }

  .gameboard-container {
    margin: 0;
    width: 80vw;
  }

  .game-status {
    text-align: center;
    position: relative;
  }

  .main-container {
    position: relative;
    top: auto;
    bottom: auto;
    padding: 0;
    padding-top: 50px;
    margin: 0;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    overflow: hidden;
  }

  .info-container {
    position: relative;
    height: auto;
    width: 80vw;
    margin-left: 0;
    margin-top: 75px;
    min-height: 350px;
    margin-bottom: 1.5rem;
  }

  .history-container {
    overflow-x: hidden;
  }
}
