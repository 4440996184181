:root {
    --square-color: #ababab;
}

.squares {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--square-color);

    font-size: 8vh;
    border-radius: 20px;
}

.top-left {
  border-radius: 2px 0 0 0;
}

.top-right {
  border-radius: 0 2px 0 0;
}

.low-left {
  border-radius: 0 0 2px 0;
}

.low-right {
  border-radius: 0 0 0 2px;
}
